/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import styled from 'styled-components'
import { Button, FlexBox, Modal, Text } from 'vspry-style-components'

import { useAppConfig } from 'context/appConfigContext'

import LanguageSelector from 'components/LanguageSelector'
import { useFetchOnLoad } from 'vspry-hooks'
import { getGeneralTerms } from 'api/terms'
import { useState } from 'react'
import { useLocale } from 'context/localeContext'

const Footer = styled.div`
    padding-bottom: 5px;
`

export default function PageFooter() {
    const { appConfig } = useAppConfig()
    const { translate } = useLocale()
    const [termsOpen, setTermsOpen] = useState(false)

    const [, terms] = useFetchOnLoad(getGeneralTerms)

    const privacyPolicy = terms?.find((t) => t.name === 'Privacy Policy')

    return (
        <Footer>
            <LanguageSelector />
            <Text size='xSmall' margin='no' $light>
                {appConfig.footer_link_url ? (
                    <a href={appConfig.footer_link_url} target='_blank' rel='noreferrer'>
                        {window.configuration['APP_VERSION']}
                    </a>
                ) : (
                    window.configuration['APP_VERSION']
                )}{' '}
                {/* eslint-disable-next-line i18next/no-literal-string */}©{' '}
                {window.configuration['APP_FOOTER'] || 'VSPRY INTERNATIONAL LIMITED 2019-2023'}
            </Text>
            {privacyPolicy && (
                <Text size='xSmall' margin='no' onClick={() => setTermsOpen(true)} color='textLink'>
                    {privacyPolicy.name}
                </Text>
            )}
            {termsOpen && privacyPolicy && (
                <Modal open>
                    <FlexBox padding='large' $column gap='medium'>
                        <Text $bold margin='no'>
                            {privacyPolicy.name}
                        </Text>
                        <iframe
                            title={translate('pages.termsAndConditions.title')}
                            srcDoc={privacyPolicy.content}
                            style={{ width: '100%', height: '50vh', border: '1px solid #0008', borderRadius: '5px' }}
                            sandbox='allow-scripts allow-popups allow-forms'
                            scrolling='auto'
                        />
                        <FlexBox gap='small'>
                            <Button id='PC-footer-privacy-policy-modal-close-button' $fitted onClick={() => setTermsOpen(!termsOpen)}>
                                {translate('buttons.close')}
                            </Button>
                        </FlexBox>
                    </FlexBox>
                </Modal>
            )}
        </Footer>
    )
}
